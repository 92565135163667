<template>
    <div class="vw-100 vh-100 row g-0">
        <div class="col-12 col-sm-4 col-lg-3 col-xl-2 bg-secondary p-3">
            <div class="d-flex flex-column h-100">
                <div>
                    <img src="https://smaot.guanajuato.gob.mx/sitio/img/sama_24_30.png" class="w-100">
                </div>
                <div class="flex-grow-1 mt-3">
                    <div>
                        <label class="form-label mb-0 text-light">Email</label>
                        <input name="email" type="text" class="form-control" v-model="email">
                        <form-error name="email" :errors="errors"></form-error>
                    </div>
                    <div>
                        <label class="form-label mb-0 text-light">Contraseña</label>
                        <input name="password" type="password" class="form-control" v-model="password"
                               @keyup.enter="login">
                        <form-error name="password" :errors="errors"></form-error>
                    </div>
                    <div>
                        <button :disabled="working"
                                class="btn btn-outline-light w-100 mt-3" @click="login">
                            Iniciar sesión
                        </button>
                    </div>
                </div>
                <div class="p-3">
                    <img src="../assets/dev_by_white.png" class="w-100">
                </div>
            </div>

        </div>
        <div class="d-none d-sm-block col-sm-8 col-lg-9 col-xl-10 login-bg">
            <div class="p-4 lh-1">
                <h4 class="mb-0">{{project_name}}</h4>
                <span>Bienvenido</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                project_name: process.env.VUE_APP_NAME ?? 'Tresfactorial',
                email: null,
                password: null,
                errors: {},
                working: false,
            }
        },
        methods: {
            login() {
                this.errors = {};
                this.working = true;
                axios.post('/login', {
                    email: this.email,
                    password: this.password,
                }).then(res => {
                    this.$router.replace('/');
                }).catch(err => {
                    this.errors = err.response.data.errors;
                    this.working = false;
                });
            }
        }
    }
</script>

<style>
    .login-bg {
        background: url("../assets/login_bg.jpg") center;
        background-size: cover;
    }
</style>