<template>
  <div style="min-width: 200px; max-width: 200px;" class="bg-tertiary p-2">
    <div class="d-flex flex-column h-100">
      <div>
        <router-link to="/">
          <img src="../../assets/menu_logo.png" class="w-100">
        </router-link>
      </div>
      <div class="flex-grow-1 my-3 text-center">
        <template v-if="user.tipo === 'foam'">
          <router-link :to="{name: 'AdminUsuariosListar'}" class="link-primary text-decoration-none d-block w-100 mb-1"
                       v-if="$permisos.can('usuarios.menu')">
            Usuarios
          </router-link>
          <router-link :to="{name: 'AdminBeneficiariosListar'}"
                       class="link-primary text-decoration-none d-block w-100 mb-1"
                       v-if="$permisos.can('beneficiarios.menu') && user_rol === user_admin">
            Beneficiarios
          </router-link>
          <router-link :to="{name: 'AdminConvocatoriasListar'}"
                       class="link-primary text-decoration-none d-block w-100 mb-1"
                       v-if="$permisos.can('convocatorias.menu')">
            Convocatorias
          </router-link>
          <router-link :to="{name: 'AdminProyectosListar'}"
                       class="link-primary text-decoration-none d-block w-100 mb-1"
                       v-if="$permisos.can('proyectos.menu')">
            Proyectos
          </router-link>
        </template>
        <template v-else-if="user_rol === user_beneficiario">
          <router-link :to="{name: 'BeneficiarioConvocatoriasListar'}"
                       class="link-primary text-decoration-none d-block w-100 mb-1"
                       v-if="$permisos.can('convocatorias.menu')">
            Convocatorias
          </router-link>
          <router-link :to="{name: 'BeneficiarioProyectosListar'}"
                       class="link-primary text-decoration-none d-block w-100 mb-1"
                       v-if="$permisos.can('proyectos.menu')">
            Proyectos
          </router-link>
        </template>

        <div v-if="$permisos.can('informes.menu.section')">
          <hr>
          <router-link :to="{name: 'AdminInformesConectrado'}"
                       class="link-primary text-decoration-none d-block w-100 mb-1"
                       v-if="$permisos.can('informes.menu')">
            Concentrado de proyectos
          </router-link>
          <router-link :to="{name: 'AdminInformesProyectos'}"
                       v-if="$permisos.can('informes.proyectos.menu')"
                       class="link-primary text-decoration-none d-block w-100 mb-1">
            Estadísticas de proyectos
          </router-link>
          <router-link :to="{name: 'AdminInformesApoyosMunicipios'}"
                       v-if="$permisos.can('informes.apoyos_municipio.menu')"
                       class="link-primary text-decoration-none d-block w-100 mb-1">
            Apoyos por municipio
          </router-link>
        </div>
      </div>
      <div class="my-2">
        <button @click="logout" class="btn btn-sm btn-outline-primary w-100">
          Cerrar sesión
        </button>
      </div>
      <div>
        <img src="../../assets/dev_by_white.png" class="w-100">
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import RolUsuario from "../../code/RolUsuario";

export default {
  name: 'Menu',
  data() {
    return {
      user_admin: RolUsuario.ADMIN,
      user_beneficiario: RolUsuario.BENEFICIARIO,
      user_revisor: RolUsuario.REVISOR,
    }
  },
  methods: {
    logout() {
      axios.post('/logout')
        .then(() => {
          this.$store.commit('setUserData', null);
          this.$store.commit('setIsLoggedIn', false);
          this.$router.replace('/login');
        });
    }
  },
  computed: {
    ...mapState({
      user: state => state && state.user && state.user.data ? state.user.data : null,
      user_rol: state => state && state.user && state.user.data ? state.user.data.rol : null,
    })
  },
}
</script>