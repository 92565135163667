const ADMIN_TITLE = 'Administrador';
const REVISOR_TITLE = 'Revisor técnico';
const BENEFICIARIO_TITLE = 'Beneficiario';
const SEGUIMIENTO_TITLE = 'Seguimiento';

const ADMIN = 'admin';
const REVISOR = 'revisor_tecnico';
const BENEFICIARIO = 'beneficiario';
const SEGUIMIENTO = 'seguimiento';

export default {
    getAll() {
        return [
            {"title": ADMIN_TITLE, "value": ADMIN},
            {"title": REVISOR_TITLE, "value": REVISOR},
            {"title": BENEFICIARIO_TITLE, "value": BENEFICIARIO},
            {"title": SEGUIMIENTO_TITLE, "value": SEGUIMIENTO},
        ];
    },
    getFoamList() {
        return [
            {"title": ADMIN_TITLE, "value": ADMIN},
            {"title": REVISOR_TITLE, "value": REVISOR},
            {"title": SEGUIMIENTO_TITLE, "value": SEGUIMIENTO},
        ];
    },
    getBeneficiarioList() {
        return [
            {"title": BENEFICIARIO_TITLE, "value": BENEFICIARIO},
        ];
    },
    ADMIN,
    REVISOR,
    BENEFICIARIO,
    SEGUIMIENTO,
}