import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/usuarios',
        name: 'AdminUsuariosListar',
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/admin/usuarios/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/usuarios/create',
        name: 'AdminUsuariosCrear',
        props: true,
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/admin/usuarios/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/usuarios/edit/:user_id',
        name: 'AdminUsuariosEditar',
        props: true,
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/admin/usuarios/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/beneficiarios',
        name: 'AdminBeneficiariosListar',
        component: () => import(/* webpackChunkName: "beneficiarios" */ '../views/admin/beneficiarios/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/beneficiarios/create',
        name: 'AdminBeneficiariosCrear',
        props: true,
        component: () => import(/* webpackChunkName: "beneficiarios" */ '../views/admin/beneficiarios/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/beneficiarios/edit/:beneficiario_id',
        name: 'AdminBeneficiariosEditar',
        props: true,
        component: () => import(/* webpackChunkName: "beneficiarios" */ '../views/admin/beneficiarios/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/convocatorias',
        name: 'AdminConvocatoriasListar',
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/admin/convocatorias/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/convocatorias/create',
        name: 'AdminConvocatoriasCrear',
        props: true,
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/admin/convocatorias/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/convocatorias/edit/:convocatoria_id',
        name: 'AdminConvocatoriasEditar',
        props: true,
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/admin/convocatorias/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/convocatorias/:convocatoria_id/participantes',
        name: 'AdminParticipantesListar',
        props: true,
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/admin/convocatorias/Participantes'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/proyectos',
        name: 'AdminProyectosListar',
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/admin/proyectos/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/convocatorias/:convocatoria_id/proyectos/edit/:proyecto_id',
        name: 'AdminProyectosEditar',
        props: true,
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/beneficiario/proyectos/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/informes/concentrado',
        name: 'AdminInformesConectrado',
        props: true,
        component: () => import(/* webpackChunkName: "informes" */ '../views/admin/informes/Concentrado'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/informes/proyectos',
        name: 'AdminInformesProyectos',
        props: true,
        component: () => import(/* webpackChunkName: "informes" */ '../views/admin/informes/StatsProyectos'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/informes/apoyosMunicipios',
        name: 'AdminInformesApoyosMunicipios',
        props: true,
        component: () => import(/* webpackChunkName: "informes" */ '../views/admin/informes/ApoyosMunicipio'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/beneficiario/convocatorias',
        name: 'BeneficiarioConvocatoriasListar',
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/beneficiario/convocatorias/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/beneficiario/convocatorias/show/:convocatoria_id',
        name: 'BeneficiarioConvocatoriasMostrar',
        props: true,
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/beneficiario/convocatorias/Mostrar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/beneficiario/convocatorias/:convocatoria_id/proyectos/create',
        name: 'BeneficiarioProyectosCrear',
        props: true,
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/beneficiario/proyectos/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/beneficiario/convocatorias/:convocatoria_id/proyectos/edit/:proyecto_id',
        name: 'BeneficiarioProyectosEditar',
        props: true,
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/beneficiario/proyectos/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/beneficiario/proyectos',
        name: 'BeneficiarioProyectosListar',
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/beneficiario/proyectos/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/beneficiario/convocatorias/:convocatoria_id/proyectos/:proyecto_id/seguimiento',
        name: 'BeneficiarioSeguimientoEditar',
        props: true,
        component: () => import(/* webpackChunkName: "convocatorias" */ '../views/beneficiario/seguimiento/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    function tryLogin(onOk, onError) {
        axios.get('api/user')
            .then(res => {
                store.commit('setUserData', res.data.data.user);
                store.commit('setIsLoggedIn', true);
                store.commit('setPermisos', res.data.data.permisos);
                onOk();
            })
            .catch(err => {
                onError();
            })
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.user.is_logged_in && store.state.user.data != null) {
            next();
        } else {
            tryLogin(() => {
                next()
            }, () => {
                next({
                    path: '/login',
                    params: {nextUrl: to.fullPath}
                });
            });
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (store.state.user.is_logged_in && store.state.user.data != null) {
            next({name: 'Home'})
        } else {
            tryLogin(() => {
                next({name: 'Home'});
            }, () => {
                next();
            });
        }
    } else {
        tryLogin(() => {
            next();
        }, () => {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            });
        });
    }
});

export default router
