<template>
  <div id="app">
    <div class="vw-100 vh-100 d-flex flex-row">
      <main-menu v-if="is_logged_in"></main-menu>
      <div class="flex-grow-1 text-primary" style="max-height: 100%; overflow-y: auto">
        <router-view/>
      </div>

      <template v-if="is_logged_in">
        <b-sidebar id="not-sidebar-right" header-class="bg-light" body-class="bg-light" right shadow
                   :visible="not_abierto">
          <template #header>
            <div class="w-100 text-end">Notificaciones</div>
            <i class="fa fa-sync ms-3 cursor-pointer" @click="recargarNotificaciones"></i>
          </template>

          <!--<div class="d-flex justify-content-end px-3 mb-3">-->
          <!--<button class="btn btn-sm btn-primary rounded-pill">Ver historial</button>-->
          <!--</div>-->
          <div class="text-center" v-if="not_cargando">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div class="d-flex flex-column px-3 py-2" style="height: 80vh;">
            <div v-infinite-scroll="getNotificaciones" class="mb-5">
              <template v-for="notificacion in notificaciones" v-if="notificaciones.length > 0">
                <notificacion-item :notificacion="notificacion"
                                   @leer-notificacion="actualizarNotificacion"></notificacion-item>
              </template>
              <div class="text-center" v-else>
                <span><b>No hay datos para mostrar</b></span>
              </div>
            </div>
          </div>

          <div
            class="d-flex justify-content-center align-items-center bg-secondary text-light rounded-circle cursor-pointer icon-notificaciones icon-ocultar-notificaciones"
            @click="ocultarNotificaciones">
            <i class="fa fa-greater-than my-auto" style="font-size: 16px;"></i>
          </div>
        </b-sidebar>

        <b-sidebar id="bit-sidebar-right" header-class="bg-light" body-class="bg-light" right shadow
                   :visible="bit_abierto">
          <template #header>
            <i class="fa fa-plus h2 cursor-pointer" @click="abrirGuardarBitacora"
               v-if="proyecto_id && proyecto_status !== proyecto_rechazado"></i>
            <div class="w-100 text-end">Bitácora</div>
            <i class="fa fa-sync ms-3 cursor-pointer" @click="recargarBitacoras"></i>
          </template>

          <!--<div class="d-flex justify-content-end px-3 mb-3">-->
          <!--<button class="btn btn-sm btn-primary rounded-pill">Ver historial</button>-->
          <!--</div>-->
          <div class="text-center" v-if="bitacora_cargando">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div class="d-flex flex-column px-3 py-2" style="height: 80vh;">
            <div v-infinite-scroll="getBitacoras" class="mb-5">
              <template v-if="bitacoras.length > 0">
                <div class="d-flex flex-column border-bottom border-3 border-dark"
                     v-for="bitacora in bitacoras">
                  <span class="fst-italic">{{ bitacora.seccion_nombre }}</span>
                  <p>{{ bitacora.texto }}</p>
                  <p class="text-end">{{ bitacora.usuario_nombre }}, {{ bitacora.fecha_creacion }}</p>
                </div>
              </template>
              <div class="text-center" v-else>
                <span><b>No hay datos para mostrar</b></span>
              </div>
            </div>
          </div>

          <div
            class="d-flex justify-content-center align-items-center bg-secondary text-light rounded-circle cursor-pointer icon-notificaciones icon-ocultar-notificaciones"
            @click="bit_abierto = false">
            <i class="fa fa-greater-than my-auto" style="font-size: 16px;"></i>
          </div>
        </b-sidebar>

        <div
          class="d-flex justify-content-center align-items-center bg-primary text-light rounded-circle cursor-pointer icon-notificaciones icon-mostrar-notificaciones"
          title="Ver notificaciones" @click="not_abierto = true" v-if="!not_abierto">
          <i class="fa fa-bell my-auto" style="font-size: 16px;"></i>
        </div>
        <div
          class="d-flex justify-content-center align-items-center bg-primary text-light rounded-circle cursor-pointer icon-notificaciones icon-mostrar-bitacora"
          title="Ver bitácora" @click="bit_abierto = true" v-if="bit_puede_ver && !bit_abierto">
          <i class="fa fa-clipboard-check my-auto" style="font-size: 16px;"></i>
        </div>
      </template>

      <b-modal id="m-guardar-bitacora" :title="(bitacora.id ? 'Editar' : 'Agregar') + ' entrada de bitácora'"
               :no-close-on-backdrop="true" header-bg-variant="secondary" body-bg-variant="light"
               footer-bg-variant="light"
               header-text-variant="light" body-text-variant="primary" footer-text-variant="primary">

        <div class="d-flex flex-column mb-2">
          <label>
            Sección del proyecto
          </label>
          <select class="selectpicker d-block" title="Seleccione" data-width="100%"
                  data-live-search="true" data-style="border"
                  v-model="bitacora.seccion">
            <option v-for="seccion in secciones" :value="seccion.value"
                    :key="seccion.value">{{ seccion.title }}
            </option>
          </select>
          <form-error name="seccion" :errors="bitacora_errors"></form-error>
        </div>
        <div class="d-flex flex-column mb-2">
          <label class="required">
            Comentario
            <text-counter :text="bitacora.texto" :length="255"></text-counter>
          </label>
          <input type="text" v-model="bitacora.texto" class="form-control" maxlength="255"
                 :disabled="bitacora_cargando">
          <form-error name="texto" :errors="bitacora_errors"></form-error>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <button class="btn btn-outline-primary btn-sm me-2" :disabled="bitacora_cargando"
                  @click="cancel()">
            Volver
          </button>
          <button class="btn btn-primary btn-sm" :disabled="bitacora_cargando"
                  @click.prevent="guardarBitacora">
            <i v-if="bitacora_cargando" class="fa fa-spinner fa-spin"></i>
            <i v-else class="fa fa-save"></i>
            Guardar
          </button>
        </template>
      </b-modal>

      <flash-alert></flash-alert>
    </div>
  </div>
</template>

<script>
import StatusProyecto from "./code/StatusProyecto";

const _ = require("lodash");
import {mapState} from 'vuex'
import {EventBus} from '@/event-bus';
import MainMenu from "./components/home/MainMenu";
import SeccionProyecto from "./code/SeccionProyecto";
import NotificacionItem from "./components/NotificacionItem";

// _not_ se refiere a notificaciones
export default {
  name: 'App',
  components: {
    MainMenu,
    NotificacionItem,
  },
  data() {
    return {
      not_cargando: false,
      not_cargado: false,
      not_page: 1,
      not_abierto: false,
      notificaciones: [],

      bit_puede_ver: false,
      bitacoras: [],
      bitacora_cargando: false,
      bitacora_cargado: false,
      bit_page: 1,
      bit_abierto: false,
      bitacora: {},
      bitacora_errors: {},
      secciones: SeccionProyecto.getAll(),

      proyecto_status: null,
      proyecto_rechazado: StatusProyecto.RECHAZADO,
    }
  },
  methods: {
    getNotificaciones() {
      if (!this.not_cargado) {
        this.not_cargando = true;
        axios.get('api/notificaciones', {
          params: {
            page: this.not_page,
          }
        }).then(res => {
          let items = res && res.data && res.data.data && res.data.data.items ? res.data.data.items.data : [];
          if (items && items.length) {
            this.notificaciones = this.notificaciones.concat(items);
            this.not_page++;
          } else {
            this.not_cargado = true;
          }
          this.not_cargando = false;
        }).catch(e => {
          console.log(e)
          this.not_cargando = false;
        })
      }
    },
    recargarNotificaciones() {
      this.notificaciones = [];
      this.not_page = 1;
      this.not_cargando = false;
      this.not_cargado = false;
      this.getNotificaciones();
    },
    actualizarNotificacion(notificacion) {
      let index = this.notificaciones.findIndex(x => x.id === notificacion.id);
      if (index) {
        this.notificaciones.splice(index, 1, notificacion);
      }
    },
    ocultarNotificaciones() {
      this.not_abierto = false;
      this.notificaciones = [];
      this.not_page = 1;
      this.not_cargado = false;
    },

    getBitacoras() {
      if (!this.bitacora_cargado) {
        this.bitacora_cargando = true;
        axios.get('api/proyectos/' + this.proyecto_id + '/bitacoras', {
          params: {
            page: this.bit_page,
          }
        }).then(res => {
          let items = res && res.data && res.data.data && res.data.data.items ? res.data.data.items.data : [];
          if (items && items.length) {
            this.bitacoras = this.bitacoras.concat(items);
            this.bit_page++;
          } else {
            this.bitacora_cargado = true;
          }
          this.bitacora_cargando = false;
        }).catch(e => {
          console.log(e)
          this.bitacora_cargando = false;
        })
      }
    },
    recargarBitacoras() {
      this.bitacoras = [];
      this.bit_page = 1;
      this.bitacora_cargando = false;
      this.bitacora_cargado = false;
      this.getBitacoras();
    },
    abrirGuardarBitacora(bitacora = {}) {
      this.bitacora = _.clone(bitacora);
      this.$bvModal.show('m-guardar-bitacora');
      this.$refreshSelectPicker();
    },
    cerrarGuardarBitacora() {
      this.$bvModal.hide('m-guardar-bitacora');
    },
    guardarBitacora() {
      this.bitacora_cargando = true;
      this.bitacora_errors = {};

      axios({
        method: this.bitacora.id ? 'put' : 'post',
        url: 'api/proyectos/' + this.proyecto_id + '/bitacoras' + (this.bitacora.id ? '/' + this.bitacora.id : ''),
        data: this.bitacora,
      }).then(response => {
        if (response.data.status === 'ok') {
          EventBus.$emit('messages.show_success', response.data.data.message);
        } else {
          EventBus.$emit('messages.show_error', response.data.data.message);
        }

        // this.bitacora_cargando = false;
        this.recargarBitacoras();
        this.cerrarGuardarBitacora();
      }).catch(error => {
        this.bitacora_cargando = false;
        console.log('error', error)
        this.bitacora_errors = error.response ? error.response.data.errors : [];

        if (error.response.status === 422) {
          EventBus.$emit('messages.show_error', 'Error de validación. Revise que todos los datos sean correctos.');
        }
      });
    },
  },
  computed: {
    ...mapState({
      is_logged_in: state => state.user.is_logged_in,
    }),
    proyecto_id() {
      return this.$route && this.$route.params && this.$route.params.proyecto_id ? this.$route.params.proyecto_id : null;
    },
  },
  watch: {
    is_logged_in(value) {
      this.not_abierto = false;
      if (value) {
        this.not_cargado = false;
        this.notificaciones = [];
        this.getNotificaciones();
      }
    },
    not_abierto(value) {
      if (value) {
        this.recargarNotificaciones();
      }
    },
    bit_abierto(value) {
      if (value) {
        this.recargarBitacoras();
      }
    },
    $route(to, from) {
      this.not_abierto = false;
      this.bit_abierto = false;
    }
  },
  created() {
    this.not_abierto = false;
    this.bit_abierto = false;
    EventBus.$on('bit_puede_ver', value => {
      this.bit_puede_ver = value;
    });
    EventBus.$on('proyecto_status', value => {
      this.proyecto_status = value;
    });
  },
}
</script>

<style scoped>
.icon-notificaciones {
  position: absolute;
  padding: 0.1rem;
  width: 40px;
  height: 40px;
}

.icon-ocultar-notificaciones {
  top: 50%;
  left: -17px;
  z-index: 10;
}

.icon-mostrar-notificaciones {
  bottom: 0;
  right: 0;
}

.icon-mostrar-bitacora {
  bottom: 0;
  right: 45px;
}
</style>