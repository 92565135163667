<template>
    <b-overlay :opacity="0.2" :show="cargando" rounded="sm">
        <div class="card bg-gray mb-2" :class="notificacion.read_at == null ? 'fw-bold' : 'text-muted'" :key="notificacion.id"
             v-on="enableClick ? { click: leerNotificacion } : { click: accionNotificacion }">
            <div class="card-body">
                <div class="d-flex flex-row">
                    <div class="flex-grow-1">
                        <p class="small">{{ formatoFecha(notificacion.created_at) }}</p>
                        <span>{{ notificacion.mensaje }}</span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center ms-1">
                        <div class="d-flex justify-content-center align-items-center rounded-circle icon-not-leida"
                             :class="notificacion.read_at == null ? 'bg-secondary text-dark' : 'bg-ligh text-primary border border-primary'">
                            <i class="fa my-auto" :class="notificacion.read_at == null ? 'fa-check' : 'fa-check-double'"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    export default {
        name: "NotificacionItem",
        props: ['notificacion'],
        data() {
            return {
                cargando: false,
            }
        },
        methods: {
            leerNotificacion() {
                this.cargando = true;
                axios.post('api/notificaciones/' + this.notificacion.id + '/leer').then(res => {
                    if (res.data.status === 'ok') {
                        this.$emit('leer-notificacion', res.data.data.result);
                        this.accionNotificacion();
                    }
                    this.cargando = false;
                }).catch(error => {
                    console.log('error', error)
                    this.cargando = false;
                })
            },
            accionNotificacion() {
                if (this.notificacion.linkable_type === 'App\\Convocatoria') {
                    this.$router.push({name: 'BeneficiarioConvocatoriasMostrar', params: {
                        convocatoria_id: this.notificacion.linkable_id
                    }});
                } else if (this.notificacion.linkable_type === 'App\\Proyecto') {
                    this.$router.push({name: 'AdminProyectosEditar', params: {
                        convocatoria_id: this.notificacion.convocatoria_id,
                        proyecto_id: this.notificacion.linkable_id,
                    }});
                }
            }
        },
        computed: {
            enableClick () {
                return !this.notificacion.read_at;
            }
        }
    }
</script>

<style scoped>
    .icon-not-leida {
        padding: 0.1rem;
        width: 30px;
        height: 30px;
        font-size: 14px;
    }
</style>