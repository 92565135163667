<script>
    /**
     * Bootstrap Style Modal Component for Vue
     * Depend on Bootstrap.css
     */

    export default {
        props: {
            p_show: {
                type: Boolean,
                twoWay: true,
                default: false
            },
            showok: {
                type: Boolean,
                default: true
            },
            showcancel: {
                type: Boolean,
                default: true
            },
            wide: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Modal'
            },
            small: {
                type: Boolean,
                default: false
            },
            no_header: {
                type: Boolean,
                default: false
            },
            large: {
                type: Boolean,
                default: false
            },
            full: {
                type: Boolean,
                default: false
            },
            force: {
                type: Boolean,
                default: false
            },
            transition: {
                type: String,
                default: 'modal'
            },
            oktext: {
                type: String,
                default: 'Aceptar'
            },
            cancelText: {
                type: String,
                default: 'Cancelar'
            },
            okClass: {
                type: String,
                default: 'btn btn-sm btn-success'
            },
            okiconclass: {
                type: String,
                default: 'fa fa-save'
            },
            cancelClass: {
                type: String,
                default: 'btn btn-sm btn-light'
            },
            canceliconclass: {
                type: String,
                default: 'fa fa-ban'
            },
            closeWhenOK: {
                type: Boolean,
                default: false
            },
            okDisabled: {
                type: Boolean,
                default: false
            },
            cancelDisabled: {
                type: Boolean,
                default: false
            },
            height: {
                type: String,
                default: '250'
            },
            width: {
                type: String,
                default: '80%'
            },
            cliente: {
                type: Boolean,
                default: false
            },
            detalles: {
                type: Boolean,
                default: false
            },
            styleValue:{
                type:Boolean,
                default:false
            }
        },
        data() {
            return {
                duration: null,
                show: false,
                error: false,
                errorMsg: '',
                working: false
            };
        },
        computed: {
            modalClass() {
                return {
                    'modal-lg': this.large,
                    'modal-sm': this.small,
                    'modal-full': this.full
                }
            },
            modalConfiguration() {

                let modal = '';
                if (this.detalles) {

                    modal = 'modal-detalles modal show mx-auto px-auto';

                } else {
                    modal = 'modal show mx-auto px-auto';
                }
                return modal;
            }
        },
        created() {
            this.show = this.p_show;
            if (this.p_show) {
                document.body.className += ' modal-open';
            }
        },
        beforeDestroy() {
            document.body.className = document.body.className.replace(/\s?modal-open/, '');
        },
        watch: {
            show(value) {
                // 在显示时去掉body滚动条，防止出现双滚动条
                if (value) {
                    document.body.className += ' modal-open';
                }
                // 在modal动画结束后再加上body滚动条
                else {
                    if (!this.duration) {
                        this.duration = window.getComputedStyle(this.$el)['transition-duration'].replace('s', '') * 1000;
                    }

                    window.setTimeout(() => {
                        document.body.className = document.body.className.replace(/\s?modal-open/, '');
                    }, this.duration || 0);
                }
            }
        },
        methods: {
            ok() {
                this.working = true;
                this.$emit('ok');
                if (this.closeWhenOK) {
                    this.show = false;
                }
            },
            cancel() {
                this.$emit('cancel');
                this.show = false;
                this.error = false;
            },
            closeModal() {
                this.show = false;
                this.error = false;
                this.working = false;
            },
            showModal() {
                this.show = true;
            },
            // 点击遮罩层
            clickMask() {
                if (!this.force && !this.working) {
                    this.cancel();
                }
            },
            setError(error) {
                this.error = true;
                this.errorMsg = error;
            },
            setWorking(bool) {
                this.working = bool;
            }
        }
    };
</script>

<template>
    <div v-if="show">
        <div class="modal show" @click.self="clickMask">
            <div class="modal-dialog" :class="modalClass"
                 :style="(wide ? 'width: 90% !important; max-width: 90% !important;' :
                 detalles ? 'width: 60% !important; max-width: 90% !important;':'')"
                 ref="dialog">
                <div class="modal-content">
                    <!--Header-->
                    <div class="modal-header" v-if="!no_header">
                        <slot name="header">
                            <h5 class="modal-title">
                                <slot name="title">
                                    {{title}}
                                </slot>
                            </h5>
                            <slot name="cancel">
                                <button v-if="!working" type="button" class="close" @click="cancel"
                                        aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </slot>
                        </slot>
                    </div>
                    <!--Container-->
                    <div class="modal-body">
                        <slot></slot>
                        <div v-if="error" class="text-danger">
                            <strong>{{errorMsg}}</strong>
                        </div>
                    </div>
                    <!--Footer-->
                    <div class="modal-footer" style="padding-bottom: 5px; padding-top: 5px;">
                        <slot name="footer">
                            <i v-if="working" class="fa fa-cog fa-spin"></i>
                            <button :disabled="working || cancelDisabled" v-if="showcancel" type="button"
                                    :class="cancelClass" @click="cancel">
                                <i :class="canceliconclass"></i> {{cancelText}}
                            </button>
                            <button :disabled="working || okDisabled" v-if="showok" type="button" :class="okClass"
                                    @click="ok">
                                <i :class="okiconclass"></i> {{oktext}}
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop in"></div>
    </div>
</template>

<style scoped>
    .modal {
        display: block;
    }

    .modal-cliente {
        position: fixed !important;
        right: 80%;
        left: -10%;
        top: 2%;

    }

    .modal-detalles {
        position: fixed;
        right: 80%;
        left: -2%;
        top: 4%;

    }

    .modal-enter-active {
        transition: all .2s ease;
    }

    .modal-leave-active {
        transition: all .2s ease;
    }

    .modal-enter, .modal-leave-to {
        /*transform: translatey(-100px);*/
        opacity: 0;
    }

    .modal-dialog-increase.modal-dialog {
        width: 800px !important;
    }

    .modal-backdrop {
        opacity: 0.7;
    }

    .close, .close:hover {
        color: black;
    }
</style>

