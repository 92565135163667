const GENERAL_TITLE = 'Datos generales';
const DIAGNOSTICO_TITLE = 'Diagnóstico';
const DESCRIPCION_TITLE = 'Descripción';
const IMPACTO_TITLE = 'Impacto';
const RESPONSABLES_TITLE = 'Responsable';
const ADICIONAL_TITLE = 'Adicional';
const OPINION_TECNICA_TITLE = 'Opinión técnica';
const PROGRAMA_TITLE = 'Programa';
const DETERMINACION_TITLE = 'Determinación';

const GENERAL = 'general';
const DIAGNOSTICO = 'diagnostico';
const DESCRIPCION = 'descripcion';
const IMPACTO = 'impacto';
const RESPONSABLES = 'responsables';
const ADICIONAL = 'adicional';
const OPINION_TECNICA = 'opinion_tecnica';
const PROGRAMA = 'programa';
const DETERMINACION = 'determinacion';

export default {
    getAll() {
        return [
            {"title": GENERAL_TITLE, "value": GENERAL},
            {"title": DIAGNOSTICO_TITLE, "value": DIAGNOSTICO},
            {"title": DESCRIPCION_TITLE, "value": DESCRIPCION},
            {"title": IMPACTO_TITLE, "value": IMPACTO},
            {"title": RESPONSABLES_TITLE, "value": RESPONSABLES},
            {"title": ADICIONAL_TITLE, "value": ADICIONAL},
            {"title": OPINION_TECNICA_TITLE, "value": OPINION_TECNICA},
            {"title": PROGRAMA_TITLE, "value": PROGRAMA},
            {"title": DETERMINACION_TITLE, "value": DETERMINACION},
        ];
    },
    GENERAL,
    DIAGNOSTICO,
    DESCRIPCION,
    IMPACTO,
    RESPONSABLES,
    ADICIONAL,
    OPINION_TECNICA,
    PROGRAMA,
    DETERMINACION,
}