const BORRADOR = 'borrador';
const EN_REVISION = 'en_revision';
const CORRECCIONES = 'correcciones';
const VALIDADO = 'validado';
const APROBADO = 'aprobado';
const RECHAZADO = 'rechazado';

const GUARDAR_FOAM = 'guardar_foam';

const BORRADOR_TITLE = 'Borrador';
const EN_REVISION_TITLE = 'En revisión';
const CORRECCIONES_TITLE = 'Con correcciones';
const VALIDADO_TITLE = 'Validado';
const APROBADO_TITLE = 'Aprobado';
const RECHAZADO_TITLE = 'Rechazado';

export default {
    getAll() {
        return [
            {"title": BORRADOR_TITLE, "value": BORRADOR},
            {"title": EN_REVISION_TITLE, "value": EN_REVISION},
            {"title": CORRECCIONES_TITLE, "value": CORRECCIONES},
            {"title": VALIDADO_TITLE, "value": VALIDADO},
            {"title": APROBADO_TITLE, "value": APROBADO},
            {"title": RECHAZADO_TITLE, "value": RECHAZADO},
        ];
    },
    getBeneficiario() {
        return [
            {"title": BORRADOR_TITLE, "value": BORRADOR},
            {"title": EN_REVISION_TITLE, "value": EN_REVISION},
            {"title": CORRECCIONES_TITLE, "value": CORRECCIONES},
            {"title": VALIDADO_TITLE, "value": VALIDADO},
            {"title": APROBADO_TITLE, "value": APROBADO},
            {"title": RECHAZADO_TITLE, "value": RECHAZADO},
        ];
    },
    getRevisor() {
        return [
            {"title": EN_REVISION_TITLE, "value": EN_REVISION},
            {"title": CORRECCIONES_TITLE, "value": CORRECCIONES},
            {"title": VALIDADO_TITLE, "value": VALIDADO},
        ];
    },
    getSeguimiento() {
        return [
            {"title": APROBADO_TITLE, "value": APROBADO},
        ];
    },
    BORRADOR,
    EN_REVISION,
    CORRECCIONES,
    VALIDADO,
    APROBADO,
    RECHAZADO,

    GUARDAR_FOAM,
}